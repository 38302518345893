<template>
  <v-window v-model="window" touchless>
    <v-window-item :key="0">
      <v-expand-transition>
        <div v-if="rosteredTeams && rosteredTeams.length === 1 && false" class="text-center">
          <v-btn
            color="color3"
            text
            @click.stop="goto({ to: {
              name: 'tournament-brochure',
              query: { tab: 'teams', id: rosteredTeams[0].id }
            }})">
            <v-img
              :src="rosteredTeams[0].logo"
              max-height="48"
              max-width="48"
              contain
            ></v-img>
            Master Schedule
            <v-icon class="ml-3">fas fa-caret-right</v-icon>
          </v-btn>
        </div>
      </v-expand-transition>
      <v-list class="mt-2">
        <template  v-for="(t, i) in teams">
          <v-divider v-if="i > 0" :key="i + 'b'"></v-divider>
          <v-list-item :key="i + 'a'" class="mini">
            <v-list-item-content class="pa-0">
              <v-list-item-title class="d-flex justify-space-between">
                <span>{{t.team.name}}</span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="pa-0" v-if="t.team.isRostered">
              <v-list-item-title class="text-end">
                <v-chip
                  small
                  class="warning black--text"
                  v-if="t.team.players.length < t.division.numOfPlayers"
                  @click.stop="manageClick(t.team)"
                >
                  <v-icon x-small class="mr-1 ml-n1 black--text">fas fa-exclamation-triangle</v-icon>
                  Roster Incomplete
                </v-chip>
                <v-chip
                  small
                  class="success white--text"
                  v-if="t.team.players.length >= t.division.numOfPlayers"
                  @click.stop="manageClick(t.team)"
                >
                  <v-icon x-small class="mr-1 ml-n1 white--text">fas fa-check</v-icon>
                  Roster Complete
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="false">
              <v-btn
                color="color3 color3Text--text"
                small fab
                @click.stop="goto(t)"
              >
                <v-icon>fas fa-caret-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item :key="i + 'c'" class="x-mini">
            <v-list-item-content class="pt-0">
              <v-list-item-subtitle class="d-flex2 justify-center">
                <v-btn
                  v-if="t.team.isRostered"
                  color="color3"
                  text x-small
                  @click.stop="manageClick(t.team)"
                >Manager Roster</v-btn>
                <v-btn
                  v-else
                  color="color3"
                  text x-small
                  @click.stop="goto(t, 'partner')"
                >Manager Team</v-btn>
                <v-btn
                  color="color3"
                  text x-small
                  @click.stop="goto(t)"
                >Jump to Schedule</v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-window-item>
    <v-window-item :key="1">
      <tournament-roster-manager
        :team="selectedTeam"
        :parentTeam="parentTeam"
        :active="window === 1"
        ref="teamManager"
        @back-click="window = 0"
        @edit-change="$emit('edit-change', $event)"
        @loading-change="$emit('loading-change', $event)"
        @current-btn-change="$emit('current-btn-change', $event)"
      ></tournament-roster-manager>
    </v-window-item>
  </v-window>
</template>

<script>
import RosteredTeam from '@/classes/RosteredTeam'
import { mapGetters } from 'vuex'
import uniq from 'lodash.uniqby'
const TournamentRosterManager = () => import('@/components/Team/Rostered/TournamentRosterManager.vue')

export default {
  props: ['teams', 'active'],
  data () {
    return {
      window: 0,
      parentTeam: null,
      loading: false,
      editing: false,
      currentBtn: null,
      selectedTeam: null
    }
  },
  computed: {
    ...mapGetters(['tournament', 'user']),
    rosteredTeams () {
      const base = this.teams.filter(f => f.team.isRostered).map(t => {
        return {
          id: t.team.rosteredTeamId,
          logo: t.team.logo
        }
      })
      return base && base.length && uniq(base, 'id')
    }
  },
  methods: {
    goto (t, tab) {
      const r = JSON.parse(JSON.stringify(t.to))
      if (tab) r.query.tab = tab
      this.$router.push(r)
      this.$emit('goto-click')
    },
    manageClick (team) {
      this.selectedTeam = team
      this.window = 1
      this.getTeam(team.rosteredTeamId)
    },
    getTeam (id) {
      this.loading = true
      this.$VBL.roster.team.get(id)
        .then(r => {
          this.parentTeam = new RosteredTeam(r.data)
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    currentBtnClick () {
      this.$refs.teamManager && this.$refs.teamManager.cancelClick()
    },
    cancelClick () {
      this.$refs.teamManager && this.$refs.teamManager.cancelClick()
    },
    backClick () {
      this.$refs.teamManager && this.$refs.teamManager.backClick()
    }
  },
  watch: {
    loading: function (v) {
      this.$emit('loading-change', v)
    },
    editing: function (v) {
      this.$emit('editing-change', v)
    },
    currentBtn: function (v) {
      this.$emit('current-btn-change', v)
    },
    window: function (v) {
      this.$emit('show-action-change', !!v)
    },
    active: function (v) {
      if (!v) {
        this.window = 0
      }
    }
  },
  components: {
    TournamentRosterManager
  }
}
</script>

<style scoped>
.mini {
  min-height: 40px;
}
.x-mini {
  min-height: 20px;
}
</style>
