import { firstBy } from 'thenby'
import DtoUpdate from './DtoUpdate'

export default class RosteredTeam {
  id = 0
  name = null
  logoUrl = null
  type = null
  ownerType = null
  ownerId = 0
  roster = []
  owner = {
    id: 0,
    name: null
  }

  constructor (dto) {
    if (dto) {
      this.update(dto)
    }
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['owner']
    DtoUpdate(dto, this, exclude)

    if (dto.owner) {
      this.owner = dto.owner
    }
    this.sortRoster()
  }

  sortRoster (by) {
    by = by || 'Jersey'
    if (by === 'Jersey') {
      this.roster = this.roster.sort(firstBy(this.jerseySort).thenBy('name'))
    } else if (by === 'TruVolley') {
      this.roster = this.roster.sort(firstBy(this.tvSort).thenBy('name'))
    } else {
      this.roster = this.roster.sort(firstBy('name').thenBy((a, b) => {
        a = a.jersey === null ? 999 : a.jersey
        b = b.jersey === null ? 999 : b.jersey
        return a === b ? 0 : a < b ? -1 : 1
      }))
    }
  }

  jerseySort (a, b) {
    a = a.jersey === null ? 999 : a.jersey
    b = b.jersey === null ? 999 : b.jersey
    return a === b ? 0 : a < b ? -1 : 1
  }

  tvSort (a, b) {
    console.log('here')
    a = a.truVolleyScore === null ? 0 : a.truVolleyScore
    b = b.truVolleyScore === null ? 0 : b.truVolleyScore
    return a === b ? 0 : a > b ? -1 : 1
  }

  get dto () {
    return {
      id: this.id,
      name: this.name,
      logoUrl: this.logoUrl,
      type: this.type,
      ownerType: this.ownerType,
      ownerId: this.ownerId,
      roster: this.roster.map(m => {
        return {
          id: m.id,
          playerProfileId: m.playerProfileId,
          name: m.name,
          jersey: m.jersey,
          picUrl: m.picUrl,
          deleted: m.deleted,
          info: m.info,
          truVolleyScore: m.truVolleyScore,
          truVolleyConfidence: m.truVolleyConfidence
        }
      }),
      owner: {
        id: this.owner.id,
        name: this.owner.name
      },
      vLogoUrl: this.vLogoUrl
    }
  }
}
