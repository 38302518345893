<template>
    <v-dialog
      v-model="myDialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <v-expand-transition>
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>My Team{{teams.length >  1 ? 's' : ''}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="color2 color2Text--text" small fab @click.stop="myDialog = false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <loading-bar v-model="loading"></loading-bar>
          <v-card-text class="pa-0">
            <my-teams-manager
              :teams="teams"
              @goto-click="myDialog = false"
              :active="myDialog"
              @show-action-change="showActions = $event"
              @edit-change="editing = $event"
              @loading-change="loading = $event"
              @current-btn-change="currentBtn = $event"
              ref="teamManager"
            ></my-teams-manager>
          </v-card-text>
          <v-divider v-if="showActions"></v-divider>
          <v-card-actions v-if="showActions">
            <v-btn
              color="color3Text color3--text"
              small fab @click.stop="backClick"
            >
              <v-icon>fas fa-caret-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-fab-transition>
              <v-btn
                color="error white--text"
                fab small
                @click="cancelClick"
                :disabled="loading"
                v-if="editing"
              >
                <v-icon>fas fa-times</v-icon>
              </v-btn>
            </v-fab-transition>
            <v-fab-transition>
              <v-btn
                v-if="currentBtn"
                :key="currentBtn.key"
                :color="currentBtn.color"
                :disabled="currentBtn.disabled"
                @click.stop="currentBtn.click"
                :loading="loading"
                fab small
              >
                <v-icon>{{currentBtn.icon}}</v-icon>
              </v-btn>
            </v-fab-transition>

          </v-card-actions>
        </v-card>
      </v-expand-transition>
    </v-dialog>
</template>

<script>
import MyTeamsManager from './MyTeamsManager.vue'

export default {
  props: ['teams', 'dialog'],
  data () {
    return {
      loading: false,
      editing: false,
      currentBtn: null,
      showActions: false
    }
  },
  computed: {
    myDialog: {
      get () {
        return this.dialog
      },
      set (v) {
        this.$emit('dialog-change', v)
      }
    }
  },
  methods: {
    currentBtnClick () {
      this.$refs.teamManager && this.$refs.teamManager.currentBtnClick()
    },
    cancelClick () {
      this.$refs.teamManager && this.$refs.teamManager.cancelClick()
    },
    backClick () {
      this.$refs.teamManager && this.$refs.teamManager.backClick()
    },
    onEditChange (v) {
      console.log(v)
    }
  },
  components: {
    MyTeamsManager
  }
}
</script>
